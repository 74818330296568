<template>
  <div id="page">
    <div class="header">
      <h1>Arqiva's Network Channels</h1>
      <h3>Last Update: 7 Nov 2024</h3>
      <p>
        To add any channel to Arqade, please contact your Account Manager or
        email <a href="mailto:arqade@arqiva.com">arqade@arqiva.com</a>.
      </p>
    </div>
    <div class="table-container">
      <div v-if="loading" class="loading-spinner">
        <div class="spinner"></div>
        Loading Channels ...
      </div>
      <table v-else>
        <thead>
          <tr>
            <th>
              <div @click="sortBy('Channel Name')" class="name">
                Channel Name <span :class="getSortClass('Channel Name')"></span>
              </div>
              <Search
                ref="searchInput"
                class="search"
                :value="searchQuery"
                :hideLabel="true"
                @updateSearch="
                  newValue => updateSearchParams('searchQuery', newValue)
                "
              />
            </th>
            <th>
              <div @click="sortBy('Genre')">
                Genre <span :class="getSortClass('Genre')"></span>
              </div>
              <gl-form-select v-model="selectedGenre" @change="filterChannels">
                <option value="">All</option>
                <option
                  v-for="genre in sortedGenres"
                  :key="genre"
                  :value="genre"
                  >{{ genre }}</option
                >
              </gl-form-select>
            </th>
            <th>
              <div @click="sortBy('Organisation')">
                Organisation <span :class="getSortClass('Organisation')"></span>
              </div>
              <gl-form-select
                v-model="selectedOrganisation"
                @change="filterChannels"
              >
                <option value="">All</option>
                <option
                  v-for="organisation in sortedOrganisations"
                  :key="organisation"
                  :value="organisation"
                  >{{ organisation }}</option
                >
              </gl-form-select>
            </th>
            <th>
              <div @click="sortBy('Variant')">
                Variant <span :class="getSortClass('Variant')"></span>
              </div>
              <gl-form-select
                v-model="selectedVariant"
                @change="filterChannels"
              >
                <option value="">All</option>
                <option
                  v-for="variant in sortedVariants"
                  :key="variant"
                  :value="variant"
                  >{{ variant }}</option
                >
              </gl-form-select>
            </th>
            <th>
              <div @click="sortBy('Format')">
                Format <span :class="getSortClass('Format')"></span>
              </div>
              <gl-form-select v-model="selectedFormat" @change="filterChannels">
                <option value="">All</option>
                <option
                  v-for="format in sortedFormats"
                  :key="format"
                  :value="format"
                  >{{ format }}</option
                >
              </gl-form-select>
            </th>
            <th>
              <div @click="sortBy('SCTE')">
                SCTE <span :class="getSortClass('SCTE')"></span>
              </div>
              <gl-form-select v-model="selectedSCTE" @change="filterChannels">
                <option value="">All</option>
                <option v-for="scte in sortedSCTEs" :key="scte" :value="scte">{{
                  scte
                }}</option>
              </gl-form-select>
            </th>
            <th>
              <div @click="sortBy('In Arqade?')">
                On Arqade <span :class="getSortClass('In Arqade?')"></span>
              </div>
              <gl-form-select
                v-model="selectedInArqade"
                @change="filterChannels"
              >
                <option value="">All</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </gl-form-select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="channel in paginatedChannels"
            :key="channel['Channel Name']"
          >
            <td class="bold">{{ channel["Channel Name"] }}</td>
            <td>{{ channel.Genre }}</td>
            <td>{{ channel.Organisation }}</td>
            <td>{{ channel.Variant }}</td>
            <td>{{ channel.Format }}</td>
            <td>{{ channel.SCTE }}</td>
            <td v-html="getIcon(channel['In Arqade?'])"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="footer">
      <p class="pagination-info">
        {{ (currentPage - 1) * itemsPerPage + 1 }} -
        {{ Math.min(currentPage * itemsPerPage, filteredChannels.length) }}
        of {{ filteredChannels.length }}
        <span v-if="isFilterActive">filtered </span>
        items

        <a
          v-if="isFilterActive"
          @click="resetFilters"
          class="reset-filters"
          v-b-tooltip.hover
          title="Click or Press 'Esc' to clear filters"
          >Reset Filters</a
        >
      </p>
      <p class="record-count">Total Channels: {{ filteredChannels.length }}</p>
      <div class="pagination">
        <button @click="firstPage" v-if="currentPage !== 1">&lt;&lt;</button>
        <button @click="prevPage" v-if="currentPage !== 1">&lt;</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          &gt;
        </button>
        <button @click="lastPage" :disabled="currentPage === totalPages">
          &gt;&gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { GlFormSelect } from "@gitlab/ui";
import Search from "@/components/Table/SearchFields/Search";
import networkChannelsData from "@/assets/networkChannelsData.json";
import _ from "lodash";

export default {
  name: "NetworkChannels",
  components: {
    GlFormSelect,
    Search
  },
  data() {
    return {
      channels: [],
      searchQuery: "",
      selectedGenre: "",
      selectedOrganisation: "",
      selectedVariant: "",
      selectedFormat: "",
      selectedSCTE: "",
      selectedInArqade: "",
      filteredChannels: [],
      sortKey: "Channel Name",
      sortOrder: "asc",
      loading: true,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  created() {
    const delay = Math.random() * (1.2 - 0.3) + 0.3;
    setTimeout(() => {
      this.channels = networkChannelsData.sort((a, b) => {
        const nameA = a["Channel Name"] || "";
        const nameB = b["Channel Name"] || "";
        return nameA.localeCompare(nameB);
      });
      this.filteredChannels = this.channels;
      this.loading = false;
      this.debouncedFilter = _.debounce(this.filterChannels, 300);
    }, delay * 1000);
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  watch: {
    searchQuery() {
      this.debouncedFilter();
    }
  },
  computed: {
    sortedGenres() {
      return [...new Set(this.channels.map(channel => channel.Genre))]
        .filter(Boolean)
        .sort();
    },
    sortedOrganisations() {
      return [...new Set(this.channels.map(channel => channel.Organisation))]
        .filter(Boolean)
        .sort();
    },
    sortedVariants() {
      return [...new Set(this.channels.map(channel => channel.Variant))]
        .filter(Boolean)
        .sort();
    },
    sortedFormats() {
      return [...new Set(this.channels.map(channel => channel.Format))]
        .filter(Boolean)
        .sort();
    },
    sortedSCTEs() {
      return [...new Set(this.channels.map(channel => channel.SCTE))]
        .filter(Boolean)
        .sort();
    },
    paginatedChannels() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredChannels.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredChannels.length / this.itemsPerPage);
    },
    isFilterActive() {
      return (
        this.searchQuery ||
        this.selectedGenre ||
        this.selectedOrganisation ||
        this.selectedVariant ||
        this.selectedFormat ||
        this.selectedSCTE ||
        this.selectedInArqade
      );
    }
  },
  methods: {
    filterChannels() {
      const query = this.searchQuery.toLowerCase();
      this.filteredChannels = this.channels.filter(channel => {
        return channel["Channel Name"].toLowerCase().includes(query);
      });

      if (this.selectedGenre) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel.Genre === this.selectedGenre
        );
      }
      if (this.selectedOrganisation) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel.Organisation === this.selectedOrganisation
        );
      }
      if (this.selectedVariant) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel.Variant === this.selectedVariant
        );
      }
      if (this.selectedFormat) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel.Format === this.selectedFormat
        );
      }
      if (this.selectedSCTE) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel.SCTE === this.selectedSCTE
        );
      }
      if (this.selectedInArqade) {
        this.filteredChannels = this.filteredChannels.filter(
          channel => channel["In Arqade?"] === this.selectedInArqade
        );
      }

      this.sortChannels();
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }
      this.sortChannels();
    },
    sortChannels() {
      this.filteredChannels = _.orderBy(
        this.filteredChannels,
        [this.sortKey],
        [this.sortOrder]
      );
    },
    getSortClass(key) {
      if (this.sortKey === key) {
        return this.sortOrder === "asc" ? "arrow-up" : "arrow-down";
      }
      return "";
    },
    getIcon(value) {
      if (value === "Yes") {
        return "✅";
      } else if (value === "No") {
        return "❌";
      } else {
        return "";
      }
    },
    clearAllFilters() {
      this.searchQuery = "";
      this.selectedGenre = "";
      this.selectedOrganisation = "";
      this.selectedVariant = "";
      this.selectedFormat = "";
      this.selectedSCTE = "";
      this.selectedInArqade = "";
      this.filterChannels();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    firstPage() {
      this.currentPage = 1;
    },
    lastPage() {
      this.currentPage = this.totalPages;
    },
    updateSearchParams(column, value) {
      this[column] = value;
      this.filterChannels();
    },
    resetFilters() {
      this.clearAllFilters();
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.resetFilters();
      } else if (event.key.length === 1) {
        console.log(event.key);
        this.$refs.searchInput.focusInput();
      }
    }
  }
};
</script>

<style scoped>
#page {
  margin-left: 1em;
  margin-right: 1em;
  min-width: 800px;
}

.header {
  margin-bottom: 1em;
}

h1 {
  margin-top: 2em;
}

h3 {
  color: grey;
  font-size: 1em;
  margin-bottom: 1em;
}

input[type="text"] {
  margin-top: 0.6em;
  padding: 0.5em;
  width: 200px;
  box-sizing: border-box;
}

.clear-filters {
  margin-left: 1em;
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

select {
  margin-top: 0.5em;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

.table-container {
  max-height: 648px;
  overflow-y: auto;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

th,
td {
  background-color: #fafafa;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
}

th {
  background-color: #f0f0f0;
  color: #999999;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
  padding: 16px 18px;
  font-weight: bold;
}

th .arrow-up::after {
  content: "▲";
  font-size: 0.6em;
  margin-left: 0.5em;
}

th .arrow-down::after {
  content: "▼";
  font-size: 0.6em;
  margin-left: 0.5em;
}

td {
  font-size: 12px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  padding: 14px 18px;
  border-bottom: 1px #dbdbdb solid;
}
td.bold {
  font-weight: 600;
}

.loading-spinner {
  text-align: center;
  padding: 1em;
  font-size: 1.2em;
}

.name {
  padding-top: 0.8em;
}
.search {
  padding-top: 6px;
  padding-left: 0;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto 1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.pagination-info {
  margin: 0;
}

.record-count {
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination button {
  margin: 0 0.5em;
  padding: 0.5em 1em;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.reset-filters {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 1em;
}
</style>
