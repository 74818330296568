import Events from "@/views/Contributions/Events.vue";
import Channels from "@/views/Contributions/Channels.vue";
import Add from "@/views/Contributions/Add.vue";
import Delivery from "@/views/Contributions/Delivery.vue";
import BulkDelivery from "@/views/Contributions/BulkDelivery.vue";

import {
  EventsAll,
  EventsImport,
  EventsCompleted,
  EventsPending,
  EventsCurrent,
  EventsCancelled,
  ChannelsAll,
  ChannelsCompleted,
  ChannelsPending,
  ChannelsCurrent,
  ChannelsCancelled
} from "@/components/Contributions";

import { ITEMS_CONTRIBUTIONS, ALIAS_ITEMS_CONTRIBUTION } from "@/constants.js";
import Show from "@/views/Contributions/Show.vue";
import NetworkChannels from "@/components/Contributions/NetworkChannels.vue";

const {
  EVENTS,
  CHANNELS,
  NETWORK_CHANNELS,
  ADD,
  DELIVERY,
  EDIT,
  SHOW,
  BULK_DELIVERY
} = ITEMS_CONTRIBUTIONS;

const {
  EVENTS_ALL,
  EVENTS_IMPORT,
  EVENTS_PENDING,
  EVENTS_CURRENT,
  EVENTS_COMPLETED,
  EVENTS_CANCELLED,
  CHANNELS_ALL,
  CHANNELS_PENDING,
  CHANNELS_CURRENT,
  CHANNELS_COMPLETED,
  CHANNELS_CANCELLED,
  ADD_FIRST,
  ADD_SECOND,
  ADD_THIRD,
  ADD_FOURTH,
  EDIT_FIRST,
  EDIT_SECOND,
  EDIT_THIRD,
  EDIT_FOURTH
} = ALIAS_ITEMS_CONTRIBUTION;

const HOST = `Contributions`;

const contributions = [
  {
    path: EVENTS,
    name: `${HOST}Events`,
    redirect: EVENTS_ALL,
    component: Events,
    children: [
      {
        path: EVENTS_ALL,
        component: EventsAll
      },
      {
        path: EVENTS_COMPLETED,
        component: EventsCompleted
      },
      {
        path: EVENTS_CURRENT,
        component: EventsCurrent
      },
      {
        path: EVENTS_PENDING,
        component: EventsPending
      },
      {
        path: EVENTS_CANCELLED,
        component: EventsCancelled
      }
    ]
  },
  {
    path: CHANNELS,
    name: `${HOST}Channels`,
    redirect: CHANNELS_ALL,
    component: Channels,
    children: [
      {
        path: CHANNELS_ALL,
        component: ChannelsAll
      },
      {
        path: CHANNELS_COMPLETED,
        component: ChannelsCompleted
      },
      {
        path: CHANNELS_CURRENT,
        component: ChannelsCurrent
      },
      {
        path: CHANNELS_PENDING,
        component: ChannelsPending
      },
      {
        path: CHANNELS_CANCELLED,
        component: ChannelsCancelled
      }
    ]
  },

  {
    path: NETWORK_CHANNELS,
    name: `${HOST}NetworkChannels`,
    component: NetworkChannels
  },

  {
    path: ADD,
    name: `${HOST}CreateADD`,
    redirect: ADD_FIRST
  },
  {
    path: ADD_FIRST,
    name: `${HOST}Add`,
    component: Add,
    alias: [ADD_SECOND, ADD_THIRD, ADD_FOURTH]
  },
  {
    path: `${EDIT}/:id`,
    name: `${HOST}Edit`,
    component: Add,
    alias: [
      `${EDIT}/:id/${EDIT_FIRST}`,
      `${EDIT}/:id/${EDIT_SECOND}`,
      `${EDIT}/:id/${EDIT_THIRD}`,
      `${EDIT}/:id/${EDIT_FOURTH}`
    ]
  },
  {
    path: `${SHOW}/:id`,
    name: `${HOST}Show`,
    component: Show
  },
  {
    path: EVENTS_IMPORT,
    name: `event-import`,
    component: EventsImport
  },
  {
    path: `${DELIVERY}/:id`,
    name: `${HOST}Delivery`,
    component: Delivery,
    props: route => ({ id: route.params.id })
  },
  {
    path: `${BULK_DELIVERY}/:id`,
    name: `${HOST}BulkDelivery`,
    component: BulkDelivery,
    props: route => ({ id: route.params.id })
  }
];

export default contributions;
