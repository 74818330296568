<template>
  <div>
    <div id="app">
      <div v-if="isAwsMarketplaceRegistration">
        <AwsMarketplaceRegistration />
      </div>
      <div v-else-if="userInfo && userInfo.signedIn">
        <header>
          <NavBar class="fixed-top" />
        </header>
        <div id="main">
          <SideBar @toggleCollapse="onToggleCollapse" />
          <div id="main-content" :class="{ collapsed: isMenuCollapsed }">
            <Expired :orgId="orgId" v-if="orgId"></Expired>
            <assistance></assistance>
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Assistance } from "@/components/Assistance";
import SideBar from "@/components/SideBarMenu/SideBar.vue";
import NavBar from "@/components/TopMenu/NavBar.vue";
import {
  ARQADE_ADMIN_PRIVILEGED_HREFS,
  ORG_ADMIN_PRIVILEGED_HREFS
} from "@/constants.js";
import userInfo from "@/mixins/userInfo.js";
import { Auth } from "aws-amplify";
import Cookies from "universal-cookie";
import AwsMarketplaceRegistration from "./views/AwsMarketplace/AwsMarketplaceRegistration.vue";
import { userMgmtApiClient } from "@/mixins/apiClients";
import Expired from "@/components/Expired.vue";
const cookies = new Cookies();
const STORE = `auth`;

export default {
  mixins: [userInfo, userMgmtApiClient],
  components: {
    Expired,
    NavBar,
    SideBar,
    Assistance,
    AwsMarketplaceRegistration
  },
  data() {
    return {
      orgId: null,
      isAwsMarketplaceRegistration: false,
      isMenuCollapsed: false,
      valueOfuserInfo: {}
    };
  },
  methods: {
    handleNavigationRights() {
      this.$router.beforeEach((to, from, next) => {
        const needsOrgAdminAccess = this.needsOrgAdminAccess(to);
        const needsArqadeAdminAccess = this.needsArqadeAdminAccess(to);
        if (!needsArqadeAdminAccess && !needsOrgAdminAccess) {
          next();
          return;
        }
        if (needsOrgAdminAccess) {
          if (this.isOrgAdminRoleActive || this.isArqadeAdminRoleActive) {
            next();
          } else {
            console.error("Insufficient rights to navigate to that route.");
          }
        }
        if (needsArqadeAdminAccess) {
          if (this.isArqadeAdminRoleActive) {
            next();
          } else {
            console.error("Insufficient rights to navigate to that route.");
          }
        }
      });

      this.guardOnEnterUrl();
      setInterval(async () => {
        await this.startTimer();
      }, 3000); // 3 seconds
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    async startTimer() {
      const timeNow = new Date().getTime() / 1000;
      const logoutTime = this.userInfo.signedOutTime;
      if (logoutTime <= timeNow) {
        if (this.isMasquerading) {
          this.userMgmtApiClient.remove("me/masquerade-org");
          cookies.remove("arqade-org-id");
        }
        this.$store.commit(`${STORE}/userInfo`, {});
        this.$router.push("/");
        Auth.signOut();
      }
    },
    onToggleCollapse(collapsed) {
      this.isMenuCollapsed = collapsed;
    },
    async updateUserInfo(idToken) {
      if (idToken) {
        userInfo.signedIn = true;
        userInfo.attributes = {
          given_name: idToken.given_name
            ? idToken.given_name
            : idToken.payload.given_name,
          family_name: idToken.family_name
            ? idToken.family_name
            : idToken.payload.family_name,
          email: idToken.email ? idToken.email : idToken.payload.email
        };

        userInfo.role = idToken["arqade:role"]
          ? idToken["arqade:role"]
          : idToken.payload["arqade:role"];
        userInfo.orgId = idToken["arqade:org_id"]
          ? idToken["arqade:org_id"]
          : idToken.payload["arqade:org_id"];
        userInfo.orgName = idToken["arqade:org_name"]
          ? idToken["arqade:org_name"]
          : idToken.payload["arqade:org_name"];
        userInfo.signedOutTime =
          idToken["auth_time"] + 86400
            ? idToken["auth_time"] + 86400
            : idToken.payload["auth_time"] + 86400;
      }

      await this.userMgmtApiClient
        .getById(userInfo.attributes.email)
        .then(user => {
          userInfo.optTimezone1 = user.optTimezone1;
          userInfo.optTimezone2 = user.optTimezone2;
          userInfo.favTimezone = user.favTimezone;
        })
        .catch(e => this.handleApiError(e));

      this.$store.commit(`${STORE}/userInfo`, userInfo);
      this.$forceUpdate();
    },
    needsOrgAdminAccess(to) {
      for (const matched of to.matched) {
        if (ORG_ADMIN_PRIVILEGED_HREFS.includes(matched.path)) {
          return true;
        }
      }
      return false;
    },
    needsArqadeAdminAccess(to) {
      for (const matched of to.matched) {
        if (ARQADE_ADMIN_PRIVILEGED_HREFS.includes(matched.path)) {
          return true;
        }
      }
      return false;
    },
    guardOnEnterUrl() {
      const needsOrgAdminAccess = this.needsOrgAdminAccess(this.$route);
      const needsArqadeAdminAccess = this.needsArqadeAdminAccess(this.$route);
      if (
        (needsOrgAdminAccess && !this.isOrgAdminRoleActive) ||
        (needsArqadeAdminAccess && !this.isArqadeAdminRoleActive)
      ) {
        this.$router.push("/");
      }
    }
  },
  async created() {
    let idToken = null;
    if (this.$route.path === "/redirect-false") {
      setTimeout(() => {
        idToken = this.parseJwt(localStorage.getItem("jwtTokenOverride"));
        this.updateUserInfo(idToken);
        this.handleNavigationRights();
        this.orgId = this.getUserOrganizationId; // activates expired component
      }, 200);
      return;
    }
    if (this.$route.path.includes("/aws-marketplace-registration")) {
      this.isAwsMarketplaceRegistration = true;
      return;
    }

    try {
      const overrideToken = localStorage.getItem("jwtTokenOverride");
      if (overrideToken) {
        console.log("using override tokens");
        idToken = this.parseJwt(localStorage.getItem("jwtTokenOverride"));
      } else if (await Auth.currentSession()) {
        console.info("Got Auth.currentSession()");
        idToken = (await Auth.currentSession()).getIdToken();
      }
    } catch (e) {
      console.error(`Got error in auth check ${JSON.stringify(e)}`);
      if (e !== "No current user") {
        console.error(`Got error in auth check ${JSON.stringify(e)}`);
      } else {
        Auth.federatedSignIn();
      }
    }

    await this.updateUserInfo(idToken);
    this.handleNavigationRights();
    this.orgId = this.getUserOrganizationId; // activates expired component
  }
};
</script>

<style lang="scss">
.gl-breadcrumbs {
  // TODO: remove when we decide to remove the BreadCrumbs component code
  opacity: 0; // hide it but keeps the height of the element
  pointer-events: none; // disable clicking on it
}

:root {
  overflow-x: hidden;
  font-family: Inter;
}

#app {
  @include font-regular-inter;
}

#main {
  display: flex;
  align-items: stretch;
}

#main-content {
  width: 100%;
  padding-left: 210px;
  transition: padding 200ms ease-in-out;

  &.collapsed {
    padding-left: 60px;
  }

  width: 100%;
  height: 100%;
}

@mixin disabledButton {
  background-color: $gray-10 !important;
  color: $gray-400 !important;
}

.gl-button.disabled {
  @include disabledButton;
}

.btn-group {
  .gl-button[aria-expanded="true"]:not(.btn-confirm) {
    background: #dbdbdb !important;
    box-shadow: inset -1px 0px 0px #bfbfbf, inset 0px -1px 0px #bfbfbf,
      inset 1px 0px 0px #bfbfbf, inset 0px 1px 0px #bfbfbf,
      inset 0px 2px 4px #bfbfbf !important;
    border-radius: 4px 0px 0px 4px !important;
  }
}

.gl-button.disabled:hover {
  @include disabledButton;
}

p {
  // font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.gl-form-select,
.gl-form-input.form-control {
  @include font-regular-inter;
}

.gl-form-checkbox {
  margin: 8px 8px !important;
}

.gl-form-checkbox.custom-control
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.gray-section {
  background: $gray-50;
  border: 1px solid $gray-200;
}

.red-label {
  color: var(--theme-color);
  font-weight: 900;
}

.gl-toggle {
  background-color: #c80032 !important;
}

.gl-toggle .toggle-icon > svg {
  fill: #c80032 !important;
}

.gl-toggle.is-checked {
  background-color: #308258 !important;
}

.gl-toggle.is-checked .toggle-icon > svg {
  fill: #308258 !important;
}

.gl-toggle .toggle-icon > svg {
  fill: #c80032 !important;
}
</style>
