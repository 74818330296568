import dayjs from "dayjs";
const CONTRIBUTIONS = [
  {
    source0Id: "648d1c51-5ebe-42cd-a8b7-22ba97cf1e67",
    source1Id: null,
    scheduleStatus: "scheduled",
    name: "Hugh Test 3692 - 5",
    description: "t",
    contributionMetadata: null,
    plannedStart: dayjs()
      .add(1, "day")
      .toISOString(),
    plannedEnd: dayjs()
      .add(1, "day")
      .add(1, "minute")
      .toISOString(),
    contributionType: "event_contribution",
    lockStreamProfile: false,
    currentAlerts0: null,
    allTimeAlerts0: null,
    currentAlerts1: null,
    allTimeAlerts1: null,
    isOpenEnded: false,
    id: "f379641e-ee7f-489f-8478-7acb68d7bd4e",
    organisationId: "90d33755-99a8-4116-b14f-eba174321cd7",
    accessLevel: "user",
    packages: [
      {
        name: "Package for cyberdyne",
        description: "d",
        visibility: "Partner",
        id: "97cac109-cde9-4225-9588-4d90940406ce",
        organisationId: "90d33755-99a8-4116-b14f-eba174321cd7"
      }
    ],
    delivery_count: 0,
    alertCount: 0,
    source0_health: "OK",
    source1_health: "N/A",
    source0_bitrate: 500000,
    source1_bitrate: "N/A"
  },
  {
    source0Id: "c45ff48a-28cf-43a3-a623-41c69a0345a0",
    source1Id: null,
    scheduleStatus: "scheduled",
    name: "Hugh Test 3692 - 5.2",
    description: "t",
    contributionMetadata: null,
    plannedStart: dayjs()
      .add(2, "day")
      .toISOString(),
    plannedEnd: dayjs()
      .add(2, "day")
      .add(1, "minute")
      .toISOString(),
    contributionType: "event_contribution",
    lockStreamProfile: false,
    currentAlerts0: null,
    allTimeAlerts0: null,
    currentAlerts1: null,
    allTimeAlerts1: null,
    isOpenEnded: false,
    id: "55a9608a-150e-42f4-ae93-8c0a9323be23",
    organisationId: "90d33755-99a8-4116-b14f-eba174321cd7",
    accessLevel: "user",
    packages: [
      {
        name: "Package for cyberdyne",
        description: "d",
        visibility: "Partner",
        id: "97cac109-cde9-4225-9588-4d90940406ce",
        organisationId: "90d33755-99a8-4116-b14f-eba174321cd7"
      }
    ],
    delivery_count: 0,
    alertCount: 0,
    source0_health: "OK",
    source1_health: "N/A",
    source0_bitrate: 500000,
    source1_bitrate: "N/A"
  }
];

export default CONTRIBUTIONS;
