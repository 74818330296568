<template>
  <div>
    <p>
      The pricing shown below includes totals from sources and contributions
      that have been previously validated.
    </p>
    <gl-form
      @submit.prevent="
        submitGenerateForm({
          quotedContributions,
          packageId: packageId,
          billableStatus: billableStatus
        })
      "
    >
      <BillableStatus
        :billableStatus="billableStatus"
        @update-billing-status="updateBillableStatus"
      />
      <p class="grand-total">Grand Total: {{ formatPrice(totalPrice) }}</p>
      <Table :items="quotedContributions" :loading="loading">
        <TableHead slot="head"></TableHead>
        <TableItem
          slot="body"
          v-for="row in quotedContributions"
          v-bind:key="row.id"
          :row="row"
          :generationError="getContributionGenerationError(row.id)"
          :quoteError="getContributionQuoteError(row.id)"
          @removeItem="removeItem"
        ></TableItem>
        <div slot="empty">
          <p>No contributions selected</p>
          <gl-button
            :href="`#${SHOW_PACKAGE}/${this.packageId}`"
            variant="confirm"
            >Back to Package page
          </gl-button>
        </div>
      </Table>
      <template v-if="quotedContributions && quotedContributions.length > 0">
        <p v-if="missingSomeQuotes">
          Some contributions cannot be quoted, they are
          <span class="missing-quote">indicated</span> above. Please remove them
          from the list to be able to continue
        </p>
        <p v-if="bulkContributions.hasInThePast" class="invalid-text">
          Some contributions are in the past, so you cannot deliver them
          together. Please remove them from the list.
        </p>
        <p v-if="bulkContributions.hasOverlapping" class="invalid-text">
          Some contributions are overlapping, so you cannot deliver them
          together. Please remove an item that overlaps.
        </p>
        <p v-if="bulkContributions.hasMixedRedundancies" class="invalid-text">
          You have a mix of single and multi source contributions. Currently we
          cannot bulk deliver when these are mixed. Please deliver single and
          multi contributions seperately.
        </p>
        <gl-form-checkbox
          id="confirmed"
          v-model="confirmed"
          required
          :disabled="
            loading ||
              missingSomeQuotes ||
              isSubmitting ||
              !this.bulkContributions.valid
          "
          ><strong
            >I confirm all the details are correct and the pricing shown above
            will be incurred as an Order per the agreement.</strong
          ></gl-form-checkbox
        >
      </template>
      <div class="buttons">
        <gl-button
          data-testid="previous-button"
          @click="$emit('previous')"
          type="button"
          >Previous
        </gl-button>
        <gl-button
          type="submit"
          variant="confirm"
          :disabled="
            loading ||
              missingSomeQuotes ||
              !confirmed ||
              isSubmitting ||
              !this.bulkContributions.valid
          "
          >{{ isSubmitting ? "Submitting" : "Submit" }}
        </gl-button>
      </div>
    </gl-form>
  </div>
</template>

<script>
import { GlForm, GlButton, GlFormCheckbox } from "@gitlab/ui";
import {
  pricingQuoteApiClient,
  contributionApiClient
} from "@/mixins/apiClients";
import pricingUtils from "@/mixins/pricingUtils";
import { deliveryApiClient } from "@/mixins/apiClients";
import BillableStatus from "@/components/Billing/BillableStatus.vue";
import Table from "@/components/Table/Table.vue";
import TableItem from "./Generate/TableItem.vue";
import TableHead from "./Generate/TableHead.vue";
import { mapGetters, mapMutations } from "vuex";
import { ITEMS_PACKAGES } from "@/constants.js";

const { SHOW: SHOW_PACKAGE } = ITEMS_PACKAGES;
export default {
  mixins: [
    contributionApiClient,
    pricingQuoteApiClient,
    pricingUtils,
    deliveryApiClient
  ],
  components: {
    GlForm,
    GlButton,
    GlFormCheckbox,
    Table,
    TableItem,
    TableHead,
    BillableStatus
  },
  props: {
    destination0: {
      type: Object,
      default: null
    },
    destination1: {
      type: Object,
      default: null
    },
    packageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      SHOW_PACKAGE,
      loading: true,
      missingSomeQuotes: false,
      confirmed: false,
      isSubmitting: false,
      billableStatus: "DEFAULT",
      totalPrice: null,
      generateErrorResponse: null,
      quoteErrorResponse: null
    };
  },
  computed: {
    ...mapGetters("packageBulkContributions", ["validatedContributions"]),
    bulkContributions() {
      return this.validatedContributions(this.packageId);
    },
    contributions() {
      const validated = this.bulkContributions;
      const contributions = validated ? validated.contributions : [];
      if (!contributions || contributions.length === 0) {
        return [];
      }
      return contributions;
    }
  },
  asyncComputed: {
    async quotedContributions() {
      this.loading = true;
      this.totalPrice = null;
      const contributions = this.contributions;
      const quoteList = await this.getQuotes({
        contributions,
        destination0: this.destination0,
        destination1: this.destination1
      });
      const merged = this.mergeContributionsWithQuotes({
        contributions,
        quoteList,
        destination0: this.destination0,
        destination1: this.destination1
      });
      this.totalPrice = this.getTotalPrice(quoteList ? quoteList : []);
      this.loading = false;
      return merged;
    }
  },
  methods: {
    ...mapMutations("packageBulkContributions", [
      "removeContribution",
      "clearContributions"
    ]),
    async removeItem(row) {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove contribution "${row.name}" from the bulk delivery?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        this.removeContribution({
          packageId: this.packageId,
          contributionId: row.id
        });
      }
    },
    getContributionGenerationError(id) {
      if (!this.generateErrorResponse) return null;
      const generateResponse = this.generateErrorResponse.find(
        item => item.contributionId === id
      );
      if (generateResponse.success) return null;
      const regex = /422: /i;
      return generateResponse.detail.replace(regex, "");
    },
    getContributionQuoteError(id) {
      if (!this.quoteErrorResponse) return null;

      const quoteResponse = this.quoteErrorResponse.find(
        item => item.contributionId === id
      );
      if (quoteResponse.success) return null;
      return quoteResponse.detail;
    },
    formatPrice(price) {
      return typeof price === "number"
        ? Intl.NumberFormat(navigator ? navigator.language : "en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol"
          }).format(price)
        : "";
    },
    updateBillableStatus(newValue) {
      this.billableStatus = newValue;
    },
    getTotalPrice(quoteList) {
      return (
        Math.round(
          quoteList.reduce((acc, quoteItem) => {
            return (acc += Number(quoteItem.quote.offer.price));
          }, 0) * 100
        ) / 100
      );
    },
    mergeContributionsWithQuotes({
      contributions,
      quoteList,
      destination0,
      destination1
    }) {
      this.missingSomeQuotes = false;
      return contributions.map(contri => {
        const quoteItem = quoteList
          ? quoteList.find(item => item.contributionId === contri.id)
          : null;
        if (!quoteItem) this.missingSomeQuotes = true;
        return {
          ...contri,
          quote: quoteItem ? quoteItem?.quote : null,
          destination0: contri.source0Id ? destination0 : null,
          destination1: contri.source1Id ? destination1 : null
        };
      });
    },
    async submitGenerateForm({
      quotedContributions,
      packageId,
      billableStatus
    }) {
      this.isSubmitting = true;
      try {
        this.generateErrorResponse = null;
        const items = quotedContributions.map(item => {
          return {
            contributionId: item.id,
            destinationId0: item.destination0.id,
            destinationId1: item.destination1 ? item.destination1.id : null,
            mediaPipelineId: item.mediaPipelineId ? item.mediaPipelineId : null,
            isStreamProfileLocked: item.lockStreamProfile,
            packageId: packageId,
            billableStatus: billableStatus,
            quote: item.quote
          };
        });

        const response = await this.deliveryApiClient.post("bulk", items);

        if (response.status !== 200) {
          this.generateErrorResponse = response.data.response;
          throw new Error(`Response status: ${response.status}`);
        }
        this.handleApiSuccess("The delivery was created successfully!");
        this.clearContributions(this.packageId);
        this.$emit("generateSuccess", quotedContributions.length);
      } catch (error) {
        this.handleApiError("Delivery failed to create");
      } finally {
        this.isSubmitting = false;
      }
    },
    async getQuotes({ contributions, destination0, destination1 }) {
      const contributionIds = contributions.map(contri => contri.id);
      const payload = {
        contributionIds: contributionIds,
        destinationId: destination0 ? destination0.id : null,
        destination2Id: destination1 ? destination1.id : null
      };

      try {
        const response = await this.pricingQuoteApiClient.put(
          "delivery/bulk",
          payload,
          {
            selfValidate: true
          }
        );
        if (response.status !== 200) {
          this.quoteErrorResponse = response.data;
          throw new Error(
            `Failed to get quotes, response status: ${response.status}`
          );
        }
        return response.data;
      } catch (e) {
        this.handleApiError(e);
        return null;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.missing-quote {
  background-color: #b9240c;
  color: white;
  padding: 0.2rem;
}
.buttons {
  display: flex;
  gap: 1.2rem;
  margin-top: 1rem;
}

.invalid-text {
  margin-top: 1.2rem;
  color: #8f4700;
}

.grand-total {
  text-align: right;
  font-weight: bold;
}
</style>
