import axios from "axios";
import { Auth } from "aws-amplify";

class HttpClient {
  constructor(
    baseUrl,
    storageProvider = window.localStorage,
    addHeaders = true
  ) {
    this.baseUrl = baseUrl;
    this.addHeaders = addHeaders;
    this.storageProvider = storageProvider;
    this.axios = axios.create({
      baseURL: baseUrl,
      validateStatus: null
    });
  }

  async getToken() {
    let idToken = null;
    try {
      idToken = await (await Auth.currentSession()).getIdToken();
    } catch (e) {
      console.error(`No id token available`);
      await Auth.federatedSignIn();
      throw e;
    }
    return (idToken || {}).jwtToken;
  }

  getOverrideToken() {
    return this.storageProvider.getItem("jwtTokenOverride");
  }
  async getHeaders() {
    if (!this.addHeaders) {
      return {};
    }
    const token = this.getOverrideToken() || (await this.getToken());
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };

    return {
      headers,
      withCredentials: true
    };
  }

  async checkTokenTimeoutResponse(request, selfValidate) {
    const response = await request;
    if (response.status >= 200 && response.status < 300) return response;
    if (
      response.status === 401 &&
      response.data.detail &&
      response.data.detail === "Logged in session limit exceeded"
    ) {
      await Auth.signOut();
    }
    if (selfValidate) return response;
    throw response.data.detail;
  }

  async post(path, body, selfValidate = false) {
    const headers = await this.getHeaders();
    return this.checkTokenTimeoutResponse(
      this.axios.post(path, body, headers),
      selfValidate
    );
  }

  async get(path) {
    return this.checkTokenTimeoutResponse(
      this.axios.get(path, await this.getHeaders())
    );
  }

  async put(path, body, selfValidate = false) {
    const headers = await this.getHeaders();
    return this.checkTokenTimeoutResponse(
      this.axios.put(path, body, headers),
      selfValidate
    );
  }

  async putImage(path, body) {
    const headers = await this.getHeaders();
    headers.headers["accept"] = "image/png";
    return this.checkTokenTimeoutResponse(this.axios.put(path, body, headers));
  }

  async delete(path) {
    return this.checkTokenTimeoutResponse(
      this.axios.delete(path, await this.getHeaders())
    );
  }

  async patch(path, body) {
    return this.checkTokenTimeoutResponse(
      this.axios.patch(path, body, await this.getHeaders())
    );
  }
}

export default HttpClient;
