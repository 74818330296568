<template>
  <div class="mx-3 my-1 pb-5">
    <Breadcrumb :items="breadcrumbItems" />
    <h1 class="d-flex justify-content-left mt-3">{{ pageTitle }}</h1>
    <gl-tabs>
      <gl-tab
        v-for="(item, index) in tabsItems"
        :key="`tab${index}`"
        @click="openTab(item.subPageName, item.subComponent, item.data)"
        :active="isSelected(item.subPageName)"
        :disabled="!isSelected(item.subPageName) && canSaveDraft"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
        </template>
      </gl-tab>
    </gl-tabs>

    <div class="text-right mt-2 mb-4">
      <gl-button-group>
        <gl-button @click="reset()" :disabled="!canSaveDraft || isLoading"
          >Reset</gl-button
        >
        <div class="position-relative">
          <gl-button @click="saveDraft()" :disabled="!canSaveDraft || isLoading"
            >Save draft</gl-button
          >
        </div>
        <gl-button
          :disabled="!canPublish || isLoading"
          variant="confirm"
          @click="publish()"
          >Publish</gl-button
        >
      </gl-button-group>
    </div>
    <component
      v-bind:is="currentComponent"
      @tablePricingWasChanged="tablePricingWasChanged"
      :tablePricing="tablePricingCom"
    ></component>
  </div>
</template>
<script>
import { MAIN_ITEMS, ITEMS_PRICING } from "@/constants.js";
import wizardGuard from "@/mixins/wizardGuard.js";
import Breadcrumb from "@/components/Breadcrumb";
import {
  MediaConnectActiveResources,
  MediaConnectDataTransferInterRegion,
  MediaConnectDataTransferInternet,
  MediaLiveInputs,
  MediaLiveOutputs,
  MediaLiveDataTransferInterRegion
} from "@/components/Pricing";
import { GlTabs, GlTab, GlButton, GlButtonGroup } from "@gitlab/ui";
import { pricingSetApiClient } from "@/mixins/apiClients";
const {
  MEDIA_CONNECT_ACTIVE_RESOURCES,
  MEDIA_CONNECT_DATA_TRANSFER_INTER_REGION,
  MEDIA_CONNECT_DATA_TRANSFER_INTERNET,
  MEDIA_LIVE_INPUTS,
  MEDIA_LIVE_OUTPUTS,
  MEDIA_LIVE_DATA_TRANSFER_INTER_REGION
} = ITEMS_PRICING;

export default {
  mixins: [wizardGuard, pricingSetApiClient],
  components: { Breadcrumb, GlTabs, GlTab, GlButton, GlButtonGroup },
  methods: {
    openTab(subPageName, subComp, data) {
      this.selectedTab = subPageName;
      if (subComp) {
        this.currentComponent = subComp;
        this.endpoint = data.endpoint;
        this.apiUniqueFields = data.apiUniqueFields;
      }
      if (this.$router.currentRoute.path !== subPageName) {
        this.$router.push(subPageName);
      }
      this.getPricing();
    },
    isSelected(subPageName) {
      return this.selectedTab
        ? this.selectedTab === subPageName
        : this.$router.currentRoute.path === subPageName;
    },
    tablePricingWasChanged(tablePricing) {
      this.tablePricing = tablePricing;
    },
    getNameForSort(price) {
      let name = "";
      this.apiUniqueFields.forEach(
        apiUniqueField =>
          (name = name
            ? name + " " + price[apiUniqueField]
            : price[apiUniqueField])
      );
      return name;
    },
    getPricing() {
      this.isLoading = true;
      this.tablePricingCom = [];
      this.pricingSetApiClient
        .getById(this.endpoint)
        .then(results => {
          results.forEach(price => {
            price.name = this.getNameForSort(price);
            price.original_price_override_draft = price.price_override_draft;
            price.price_override =
              price.price_override !== null
                ? price.price_override
                : price.price;
            this.tablePricingCom.push(price);
          });

          this.tablePricingCom = this.tablePricingCom.sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
          });

          this.tablePricing = JSON.parse(JSON.stringify(this.tablePricingCom));

          this.originalTablePricing = JSON.parse(
            JSON.stringify(this.tablePricing)
          );

          this.isLoading = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.handleApiError(e);
        });
    },
    async reset() {
      const userReply = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to reset?`,
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        }
      );
      if (userReply) {
        this.tablePricingCom = JSON.parse(
          JSON.stringify(this.originalTablePricing)
        );
      }
    },
    async saveDraft() {
      this.isLoading = true;
      const tablePricing = this.tablePricing
        .filter(
          price =>
            price.price_override_draft !== price.original_price_override_draft
        )
        .map(price => {
          return {
            id: price.id,
            price_override:
              price.price_override_draft || price.price_override_draft === 0
                ? price.price_override_draft
                : null
          };
        });

      if (tablePricing.length) {
        try {
          const result = await this.pricingSetApiClient.update(
            this.endpoint,
            tablePricing
          );
          this.tablePricing.map(price => {
            price.original_price_override_draft = price.price_override_draft;
          });
          this.tablePricingCom = JSON.parse(JSON.stringify(this.tablePricing));
          this.originalTablePricing = JSON.parse(
            JSON.stringify(this.tablePricing)
          );
          this.handleApiSuccess(
            `Pricing draft was updated succesfully. Updated records: ${result.updated}`
          );
        } catch (e) {
          this.handleApiError(e);
        }
      }
      this.isLoading = false;
    },
    async publish() {
      await this.saveDraft();
      this.isLoading = true;
      this.pricingSetApiClient
        .update(this.endpoint + "/publish")
        .then(e => {
          this.isLoading = false;
          this.tablePricing.map(price => {
            price.price_override =
              price.price_override_draft || price.price_override_draft === 0
                ? price.price_override_draft
                : price.price;
          });
          this.tablePricingCom = JSON.parse(JSON.stringify(this.tablePricing));
          this.originalTablePricing = JSON.parse(
            JSON.stringify(this.tablePricing)
          );
          this.handleApiSuccess(
            `Pricing was updated succesfully. Updated records: ${e.published}`
          );
        })
        .catch(e => {
          this.isLoading = false;
          this.handleApiError(e);
        });
    }
  },
  created() {
    this.tabsItems.forEach(tab => {
      if (this.isSelected(tab.subPageName)) {
        this.openTab(tab.subPageName, tab.subComponent, tab.data);
      }
    });
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: `Arqade`, href: MAIN_ITEMS.ROOT },
        { text: `Pricing`, href: MAIN_ITEMS.PRICING }
      ];
    },
    tabsItems() {
      return [
        {
          title: `MediaConnect Active Resources`,
          subPageName: MEDIA_CONNECT_ACTIVE_RESOURCES,
          subComponent: MediaConnectActiveResources,
          data: {
            endpoint: "mc/active-resource",
            apiUniqueFields: ["region_name"]
          }
        },
        {
          title: `MediaConnect Data Transfer (Inter-Region)`,
          subPageName: MEDIA_CONNECT_DATA_TRANSFER_INTER_REGION,
          subComponent: MediaConnectDataTransferInterRegion,
          data: {
            endpoint: "mc/data-transfer",
            apiUniqueFields: ["from_region_name", "to_region_name"]
          }
        },
        {
          title: `MediaConnect Data Transfer (Internet)`,
          subPageName: MEDIA_CONNECT_DATA_TRANSFER_INTERNET,
          subComponent: MediaConnectDataTransferInternet,
          data: {
            endpoint: "mc/data-transfer-internet",
            apiUniqueFields: ["region_name"]
          }
        },
        {
          title: `MediaLive Inputs`,
          subPageName: MEDIA_LIVE_INPUTS,
          subComponent: MediaLiveInputs,
          data: {
            endpoint: "ml/input",
            apiUniqueFields: [
              "region_name",
              "codec",
              "resolution",
              "bit_rate",
              "redundancy"
            ]
          }
        },
        {
          title: `MediaLive Outputs`,
          subPageName: MEDIA_LIVE_OUTPUTS,
          subComponent: MediaLiveOutputs,
          data: {
            endpoint: "ml/output",
            apiUniqueFields: [
              "region_name",
              "codec",
              "resolution",
              "video_quality",
              "bit_rate",
              "redundancy"
            ]
          }
        },
        {
          title: `MediaLive Data Transfer (Inter-Region)`,
          subPageName: MEDIA_LIVE_DATA_TRANSFER_INTER_REGION,
          subComponent: MediaLiveDataTransferInterRegion,
          data: {
            endpoint: "ml/data-transfer",
            apiUniqueFields: ["from_region_name", "to_region_name"]
          }
        }
      ];
    },
    pageTitle() {
      return `Pricing`;
    },
    canSaveDraft() {
      return (
        this.tablePricing.filter(
          price =>
            price.price_override_draft != price.original_price_override_draft
        ).length > 0
      );
    },
    canPublish() {
      return (
        this.canSaveDraft ||
        this.tablePricing.filter(
          price => price.price_override != price.original_price_override_draft
        ).length > 0
      );
    }
  },
  data() {
    return {
      currentComponent: MediaConnectActiveResources,
      endpoint: "mc/active-resource",
      apiUniqueFields: ["region"],
      selectedTab: null,
      originalTablePricing: [],
      tablePricing: [],
      tablePricingCom: [],
      isLoading: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeaveWithCondition(this.canSaveDraft, to, from, next);
  }
};
</script>
